<template>
    <el-container>
        <el-main>
            <el-form class="el-form-search" label-width="120px">
                <el-form-item label="店铺名称：">
                    <el-input size="small" placeholder="请输入订单编号" v-model="form.store_name"></el-input>
                </el-form-item>
                <el-form-item label="店铺电话：">
                    <el-input size="small" placeholder="请输入店铺电话" v-model="form.store_phone"></el-input>
                </el-form-item>
                <el-form-item label="商家姓名：">
                    <el-input size="small" placeholder="请输入商家姓名" v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="商家电话：">
                    <el-input size="small" placeholder="请输入商家电话" v-model="form.mobile"></el-input>
                </el-form-item>
                <el-form-item label="小程序名称：">
                    <el-input size="small" placeholder="请输入小程序名称" v-model="form.wxname"></el-input>
                </el-form-item>

                <el-form-item label="小程序状态：">
                    <el-select v-model="form.status" size="small" placeholder="请选择">
                        <el-option v-for="item in status" :key="item.val" :label="item.label" :value="item.val"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label=" " label-width="45px">
                    <el-button type="primary" size="small" @click="getList(1)">搜索</el-button>
                    <el-button plain size="small" @click="cancelSearch">清空搜索条件</el-button>
                </el-form-item>
            </el-form>
            <!-- 表格 -->
            <el-table fit :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" class="table">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column label="商家信息" align="center">
                    <template slot-scope="scope">
                        <div class="storeinfo">
                            <img :src="scope.row.store_logo" alt="">
                            <div>{{ scope.row.contact_name }}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="store_phone" label="商家电话" align="center"></el-table-column>
                <el-table-column prop="fname" label="服务商姓名" align="center"></el-table-column>
                <el-table-column prop="store_name" label="店铺名称" align="center"></el-table-column>
                <el-table-column prop="wname" label="小程序名称" align="center"></el-table-column>
                <el-table-column prop="appstatus" label="小程序状态" align="center">
                    <template slot-scope="scope">{{
                        status.find(v => v.val == scope.row.appstatus) ? status.find(v => v.val ==
                            scope.row.appstatus).label : '--'
                    }}</template>
                </el-table-column>
                <el-table-column prop="appstatus" label="小程序版本" align="center">
                    <template slot-scope="scope">
                        <div :style="{ 'color': scope.row.version_online == scope.row.version ? '#333' : 'red' }"> {{
                            scope.row.version_online }}{{
        scope.row.version_online == scope.row.version ? '（最新）' : '（需要更新）' }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="appstatus" label="餐饮版" align="center">
                    <template slot-scope="scope">{{ scope.row.is_new_version ? '新版本' : '旧版本' }}</template>
                </el-table-column>
                <el-table-column prop="appstatus" label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.version_online != scope.row.version"
                            @click="$router.push({ path: '/extension/gatherTweeters/authorizationWx', query: { id: scope.row.store_id } })"
                            type="text">更新版本</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <Paging :total="total" :page="form.page" :pageNum="form.rows" @updatePageNum="updateData"></Paging>
        </el-main>
    </el-container>
</template>
  
<script>
import Paging from '@/components/paging'
export default {
    components: {
        Paging
    },
    data () {
        return {
            form: {
                page: 1,
                rows: 10,
                store_name: '',
                store_phone: '',
                name: '',
                mobile: '',
                wxname: '',
                status: ''
            },
            status: [
                {
                    val: 1,
                    label: '已授权'
                },
                {
                    val: 2,
                    label: '已上传'
                },
                {
                    val: 3,
                    label: '提审'
                },
                {
                    val: 4,
                    label: '审核成功'
                },
                {
                    val: 5,
                    label: '审核失败'
                },
                {
                    val: 6,
                    label: '发布'
                }
            ],
            list: [],
            total: 0
        };
    },
    created () {
        this.getList()
    },
    methods: {
        // 分页操作
        updateData (val, status) {
            if (status == 0) {
                this.form.rows = val
            } else {
                this.form.page = val
            }
            // 重新获取数据
            this.getList()
        },
        cancelSearch () {
            this.form = {
                page: 1,
                rows: 10,
                store_name: '',
                store_phone: '',
                name: '',
                mobile: '',
                wxname: '',
                status: ''
            }
            this.getList()
        },
        // 获取列表
        getList (page = 0) {
            if (page) {
                this.form.page = 1
            }
            this.$axios.post(this.$api.serviceProvider.wxAppList, this.form)
                .then((res) => {
                    if (res.code == 0) {
                        this.list = res.result.list
                        this.total = res.result.total
                    } else {
                        this.$message.error(res.msg)
                    }
                })
        },
    }
};
</script>
  
<style lang="less" scoped>
.el-main {
    background: #fff;
    display: flex;
    flex-direction: column;
}

.table {
    margin-top: 15px;
    flex: 1;
}

.inputClass {
    width: 90%;
}

.el-form-item {
    margin-bottom: 22px;
}

.storeinfo {
    display: flex;
    align-items: center;

    img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 10px;
    }
}
</style>
  